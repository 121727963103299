import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MenuItem, MessageService} from 'primeng/api';
import {ImportEmployeeInput} from '../../../generated/graphql';
import {EmployeeService} from '../../employee/employee.service';
import {ToastModule} from 'primeng/toast';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {UsecsvAngularPluginModule} from "@usecsv/angular";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html',
  styleUrls: ['./header-top.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [ToastModule, BreadcrumbModule, UsecsvAngularPluginModule, NgIf]
})
export class HeaderTopComponent implements OnInit {

  @Input()
  showEmployeeImportButton = false;
  @Input()
  title: string | undefined
  @Input()
  breadcrumbs!: MenuItem[]

  constructor(
    private employeeService: EmployeeService,
    private messageService: MessageService
  ) {
  }

  ngOnInit(): void {

  }

  onDataCallback(data: any) {
    if (data) {
      console.log(data)
      let input: ImportEmployeeInput = {
        inputs: []
      };
      input.inputs = data.rows.map((data: any) => {
        return {
          firstName: data['firstName'].toString(),
          email: data['email'].toString(),
          lastName: data['lastName'].toString(),
          dateOfBirth: new Date(data['dateOfBirth'].toString()),
          skills: [],
          teams: [],
        }
      })
      this.employeeService.importEmployees(input).subscribe(({data, errors}) => {
        if (data?.importEmployee) {
          this.messageService.add({
            severity: 'success',
            summary: 'Erfolg',
            detail: 'Mitarbeiter erfolgreich importiert'
          });
        } else {
          alert("Fehler")
          // this.messageService.add({ severity: 'error', summary: 'Success', detail: 'Message Content' });
        }
      })
    }
  }
}
